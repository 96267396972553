.container {
  align-items: center;
  background-color: #111;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: scroll;
  position: absolute;
  width: 100%;
}

.terminal {
  color: #ccc;
  font-family: "Ubuntu Mono", monospace;
  font-size: 30px;
  line-height: 30px;
  height: 80%;
  width: 80%;
}
